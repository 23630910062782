import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Properties from './components/Properties';
import FAQ from './components/FAQ';
import Testimonials from './components/Testimonials';
import Services from './components/Services';

import HomeSpanish from './components/HomeSpanish'; // Your Spanish version of Home
import HomeRussian from './components/HomeRussian'; // Your Russian version of Home

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/es" element={<HomeSpanish />} />
          <Route path="/ru" element={<HomeRussian />} />

        </Routes>
      </Layout>
    </Router>
  );
};

export default App;