import React from 'react';
import { AppBar, Toolbar, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Argentina from '../assets/argentina.png'
import Murica from '../assets/murica.png'
import Russia from '../assets/russia.png'

function ButtonLink({ to, children }) {
  return <Link to={to}><button>{children}</button></Link>;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent', // Keeps the AppBar transparent on desktop
  boxShadow: 'none',
  position: 'fixed', // Use fixed positioning for both desktop and mobile
  top: 0, // AppBar will be at the top for desktop
  
  zIndex: 1201,
}));

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <StyledAppBar>
        <Toolbar>
          <Typography variant="h4" style={{ color: 'black', flexGrow: 1 }}>
            {/* Your Logo or Title Here */}
          </Typography>
          {!isMobile && (
            <>
             {/* <Button style={{ color: 'black' }} color="inherit">Referral</Button> */}
              <Button onClick={() => navigateTo('/es')} style={{ fontSize: '40px' }}> <img src={Argentina} alt="" style={{ width: '40px', marginRight:'-50px'}} /> </Button>
              <Button onClick={() => navigateTo('/')} style={{ fontSize: '40px' }}><img src={Murica} alt="" style={{ width: '40px', marginRight:'-40px'}} /> </Button>
          <Button onClick={() => navigateTo('/ru')} style={{ fontSize: '40px' }}><img src={Russia} alt="" style={{ width: '40px', marginRight:'-30px'}} /></Button>
            </>
          )}
          {isMobile && (
            <>
              <Button style={{padding: '0px', marginRight:'-17px',width: '0px'}}onClick={() => navigateTo('/es')} ><img src={Argentina} alt="" style={{ width: '35px',}} /></Button>
          <Button onClick={() => navigateTo('/')} style={{ fontSize: '40px',marginRight:'-17px' }}><img src={Murica} alt="" style={{ width: '35px'}} /> </Button>
          <Button onClick={() => navigateTo('/ru')} style={{ fontSize: '40px',marginRight:'-20px' }}><img src={Russia} alt="" style={{ width: '35px', }} /></Button>
 

            </>
          )}
        </Toolbar>
      </StyledAppBar>
      <div style={{ paddingTop: isMobile ? '0' : '0px' }}> {/* Adjust top padding based on AppBar height */}
        {children}
      </div>
    </div>
  );
};

export default Layout;
